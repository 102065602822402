import { lazy, Suspense, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LocalRender from "./Components/Localization/Components/LocalRender";
import Localization from "./Components/Localization/Components/Localization";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AiOutlineMessage } from "react-icons/ai";
import ChatBot from "./Components/ChatBot/ChatBot";
import { Context } from "./Context/ContextProvider";
import { getList } from "./ConfigFiles/SharedFunctions";
import { urls } from "./ConfigFiles/Consts";
import { getUserfromSS } from "./ConfigFiles/SmallFunctions";
// import "./Bootstrap/css/bootstrap.min.css"

const Login = lazy(() => import("./Components/Authentication/Login"));
const PatientRegister = lazy(() => import("./Components/Authentication/PatientRegistration"));
const BookAmbulance = lazy(() => import("./Components/Authentication/BookAmbulance"));
const AccessLinksNotAssigned = lazy(() => import("./Components/Authentication/AccessLinksNotAssigned"))
const CreatePassword = lazy(() => import("./Components/Authentication/CreatePassword"));
const RoutingConfig = lazy(() => import("./Components/RoutingConfig"));
const PrivateRoute = lazy(() => import("./PrivateRoutes/PrivateRoute"));
const Privacy = lazy(() => import("./Components/Authentication/Privacy"));
const AppointmentDetails = lazy(() => import("./Components/Servicemanagement/Models/AppointmentDetails"));
const PatientConsent = lazy(() => import("./Components/Servicemanagement/Models/PatientConcent"));
const Razorpay = lazy(() => import("./ThirdPartyIntegrations/Razorpay/OpenRaz"));
const AppointmentFeedback = lazy(() => import("./Components/Servicemanagement/Appointments/Modals/Feedbackform"))
const TermsAndConditions = lazy(() => import("./Components/Authentication/TermsAndConditions"))
const App = () => {
  // const language = useSelector((state) => state.language);
  const { language } = Context();
  const [isRtl, setIsRtl] = useState(false);
  const { t, i18n } = useTranslation();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  const changeLanguage = (lng) => {
    lng == "Arabic" ? setIsRtl(true) : setIsRtl(false);
    i18n.changeLanguage(lng);
  };
  const handleParentClick = () => setIsChatbotOpen(false)

  const getAccessLinks = async () => {
    let res1 = await getList(urls?.UserGroups?.GetAllAccessLinksByUser, {});
    let links = res1?.map((e) => e.linkName);
    sessionStorage.setItem("accessLinks", JSON.stringify(links));
  }
  useEffect(() => {
    getUserfromSS()?.roleId > 0 && getAccessLinks();
  }, []);
  useEffect(() => {
    changeLanguage(language)
  }, [language])

  return (
    <div style={{ direction: isRtl ? "rtl" : "ltr", backgroundColor: "#f6f7fa" }}  >
      {/* <div onClick={handleParentClick}> */}
      <Suspense fallback={() => <div>Loading ....</div>}>
        <Router>
          <Routes>
            <Route path="*" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/accessLinksNotAssigned" element={<AccessLinksNotAssigned />} />
            <Route path="/pramotion" element={<Login />} />
            <Route path="/register" element={<PatientRegister />} />
            <Route path="/bookambulance" element={<BookAmbulance />} />
            <Route path="*" element={<Login />} />
            <Route path="/createpassword/:token" element={<CreatePassword />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/appointmentDetails/:id" element={<AppointmentDetails />} />
            <Route path="/payment/:appointmentId/:amount" element={<Razorpay />} />
            <Route path="/feedback/:id" element={<AppointmentFeedback />} />
            <Route
              path="v1/*"
              element={<PrivateRoute><RoutingConfig rtl={isRtl} /></PrivateRoute>}
            />
          </Routes>
        </Router>
      </Suspense>
      {/* </div> */}
      {/* <div className="chatbot-button ">
        <AiOutlineMessage
          onClick={() => setIsChatbotOpen(!isChatbotOpen)}
          size={30}
        />
        {isChatbotOpen && <ChatBot />}
      </div> */}
    </div>
  );
};

export default App;
