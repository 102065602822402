import moment from "moment/moment";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";
import { css, encryptKey, ivKey } from "./Consts";
import { BsExclamationCircle, BsHandThumbsUp } from "react-icons/bs";
import { GiConfirmed } from "react-icons/gi"
import { paymentStatus } from "./ConstList";
import { LiaPaypal } from "react-icons/lia";
import CashPending from "../Images/cashPending.png"
import CashSuccess from "../Images/cashSuccess.png"
import OnlinePending from "../Images/onlinePending.png"
import OnlineSuccess from "../Images/onlineSuccess.png"
import PayLaterPending from "../Images/payLAterPending.png"
import PayLaterSuccess from "../Images/payLaterSuccess.png"
import * as XLSX from 'sheetjs-style';
import BulkPending from "../Images/bulkPending.png"
import BulkSuccess from "../Images/bulksuccess.png"
import { sidenavbars } from "../SharedComponents/NewNavBarConst";
// import * as XLSX from "xlsx";

export const removeSpaces = (v) => {
  return v?.replace(/\s+/g, '')
}



export const returningValue = (v, type) => {
  if (v && v != "" && v != undefined && v != null && v != NaN && v != "null") {
    switch (type) {
      case "Date": return new Date(v) != "Invalid Date" ? new Date(v) : "";
      case "Phone":
        let n = v?.replace("+966", "")?.replace(/[^0-9]/g, "")?.replace(/\s+/g, '')
        let res = n
        let l = res?.length
        if (l > 0 && l <= 2) {
          return res?.toString()?.replace(/(\d{1})/, "+966 $1", "");
        } else if (l > 2 && l <= 5) {
          return res?.toString()?.replace(/(\d{2})(\d{1})/, "+966 $1 $2", "");
        } else if (l > 5 && l <= 9) {
          return res?.toString()?.replace(/(\d{2})(\d{3})(\d{1})/, "+966 $1 $2 $3", "");
        }
      case "Email": return v?.toLowerCase()?.replace(/\s+/g, '')
      case "Number": return parseInt(v);
      case "DecimalPrice": return parseFloat(v).toFixed(2);
      case "Decimal": return parseFloat(v).toFixed(2);
      case "Discount": return parseFloat(v)?.toFixed(6);
      case "DateTime": return v == "Invalid date" ? "-" : v;
      case "LocalTime": return v == "Invalid date" ? "-" : moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('YYYY-MMM-DD hh:mm')
      case "LocalDateTime": return v == "Invalid date" ? "-" : moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('DD/MM/YYYY hh:mm A')
      case "LocalDateTimeFormat": return v == "Invalid date" ? "-" : moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('DD/MM/YYYY H:mm')
      case "Time": return v == "Invalid date" ? "-" : moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('hh:mm A')
      case "DateFormat": return v == "Invalid date" ? "-" : moment.utc(v, "YYYY-MM-DDTHH:mm:ss").local().format('DD/MM/YYYY')
      case "ColumnTime": return moment(v)?.format("hh:mm A")
      case "Alphabet":
      case "AlphaNumaric":
      case "NumberString": return v?.toString();
      case "Array": return (Array.isArray(v) && v?.length > 0) ? v : []
      case "RemoveSpaces": return v?.replace(/\s+/g, '')
      // case "Phone":
      //   v?.toString();
      default:
        return v;
    }
  }
  return type == "Array" ? [] : "";
};

export const returnErrorColor = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != "" ? "border border-danger rounded" : "";
};

export const returnErrorMessage = (condition) => {
  let v = condition;
  return v && v != undefined && v != null && v != "" ? v : "";
};

export const checkedFn = (data, value) => {
  return data && data == value ? true : false;
};
// export const phoneNumberValue = (data, key) => {
//   let v = data?.[key];
//   return v && v != "" && v != undefined && v != null ? v?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
// };

// export const reconvertingPhonenumber = (data, array) => {
//   array.map((e, i) => {
//     if (data[e] && data[e] != "" && data[e].length != 0) {
//       data[e] = data[e].replace(/[^0-9]/g, "");
//     }
//   });
//   // return data
// };

// export const reconvertingPhonenumberinArray = (dataArray, array) => {
//   dataArray?.map((data, i) => {
//     array?.map((e, j) => {
//       if (data[e] && data[e] != "" && data[e].length != 0) {
//         data[e] = data[e]?.toString()?.replace(/[^0-9]/g, "");
//       }
//     });
//   });
// };

export const getUserfromSS = (key) => {
  return key ? JSON.parse(sessionStorage.getItem("user"))?.[key] || "" : JSON.parse(sessionStorage.getItem("user"));
};

// export const stringToArray = (array, data, id, label) => {
//   let temp = [];
//   if (array?.length > 0) {
//     let tempArray = data?.split(",");
//     tempArray?.map((e, i) => {
//       let t = array.filter((v) => v?.[id] == parseInt(e))?.[0];
//       if (t != undefined) {
//         temp.push({ label: t?.[label], value: t?.[id] });
//       }
//     });
//   }
//   return temp || [];
// };

// export const stringToArray2 = (array, data) => {
//   let temp = [];
//   if (array?.length > 0) {
//     let tempArray = data?.split(",");
//     tempArray?.map((e, i) => {
//       temp.push({ label: e, value: e });
//     });
//   }
//   return temp || [];
// };

export const returnStatusColor = (status) => {
  switch (status) {
    case "Waiting List":
      return css.Requested;
    case "Confirmed":
      return css.Booked1;
    case "Completed":
      return css.Preadmission1;
  }
};
export const returnStatusIcon = (status) => {
  switch (status) {
    case "Waiting List":
      return <BsExclamationCircle size={22} title="Waiting List" />;
    case "Confirmed":
      return <GiConfirmed size={22} title="Confirmed" />;
    case "Completed":
      return <BsHandThumbsUp size={22} title="Completed" />;
  }
};
// export const returnPaymetIcon = (q) => {
//   switch (q.paymentStatus) {
//     case "Paid":
//       return <LiaPaypal size={22} title="Paid" color="green" />;
//     case "Pending":
//       return <LiaPaypal size={22} title="Pending" color="red" />;
//   }
// }

export const appointmentStatusList = (currentStatus) => {
  switch (currentStatus) {
    case "Waiting List":
      return ["Waiting List", "Confirmed"];
    case "Confirmed":
      return ["Confirmed", "Completed"];
    case "Completed":
      return ["Completed"];
  }
  return [];
};

// export const returnStatusColor = (status) => {
//   switch (status) {
//     case "Requested":
//       return css.Request;
//     case "Booked":
//       return css.Booked;
//     case "Consulted":
//       return css.Preadmission;
//     case "Collected":
//       return css.Collected;
//     // case "Procedure":
//     //   return css.Admitted;
//     case "In Lab":
//       return css.Complete;
//     case "InSurgery":
//       return css.Booked;
//     case "Discharge":
//       return css.Preadmission;
//     case "Completed":
//       return css.Complete;
//     case "WaitList":
//       return css.pending;
//     case "Cancelled":
//       return css.red;
//   }
// };

// export const appointmentStatusList = (currentStatus) => {
//   switch (currentStatus) {
//     case "WaitList":
//       return ["WaitList", "Booked"];
//     case "Cancelled":
//       return ["Cancelled"];
//     case "Requested":
//       return ["Requested", "Booked", "Consulted", "Completed"];
//     case "Booked":
//       return ["Booked", "Consulted", "Completed"];
//     case "Consulted":
//       return ["Consulted", "Completed"];
//     // case "Procedure":
//     //   return ["Procedure", "InSurgery", "Discharge", "Completed"];
//     case "InSurgery":
//       return ["InSurgery", "Discharge", "Completed"];
//     case "Discharge":
//       return ["Discharge", "Completed"];
//     case "Completed":
//       return ["Completed"];
//   }
//   return [];
// };

// export const labAppointmentSTatusList = (currentStatus) => {
//   switch (currentStatus) {
//     case "Requested":
//       return ["Requested", "Collected", "In Lab", "Completed"];
//     case "Collected":
//       return ["Collected", "In Lab", "Completed"];
//     case "In Lab":
//       return ["In Lab", "Completed"];
//     case "Completed":
//       return ["Completed"];
//   }
//   return [];
// };

export const settingdata = (data, keys) => {
  let temp = {};
  keys.map((e, i) => {
    temp[e] = data[e] || "";
  });
  return temp;
};

export const resettingdata = (keys) => {
  let temp = {};
  keys.map((e, i) => {
    temp[e] = "";
  });
  return temp;
};

export const decryPtiedLinks = () => {
  let links = sessionStorage.getItem("accessLinks");
  if (links && links != undefined && links != "") {
    return JSON.parse(links);
    var bytes = CryptoJS.AES.decrypt(links, "COLINA");
    // console.log(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)), "here");
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    return [];
  }
};
export const CheckLinks = (linkNames) => decryPtiedLinks()?.some((v) => linkNames?.some((a) => a == v));

// export const resettingdata = (keys) => keys.reduce((temp, key) => Object.assign(temp, { [key]: "" }), {});

// export const dateinformate = (v) => {
//   return v != null && v != "" && v != undefined ? moment(v).format("MM-DD-YYYY") : " NA ";
// };

export const logOutFunction = () => {
  sessionStorage.clear();
  window.location.pathname = "/";
};

export const checkLoginStatus = () => {
  let authenticated = false;
  let token = sessionStorage.getItem("token");
  if (Object.keys(jwtDecodedData())?.length > 0 && token != "") {
    authenticated = (jwtDecodedData()?.id == getUserfromSS("userId")) == true ? true : false;
  }
  return authenticated;
};

export const jwtDecodedData = () => {
  if (sessionStorage.getItem("token") && sessionStorage.getItem("token") != "") {
    let res = jwt_decode(sessionStorage.getItem("token"));
    return res;
  }
  return {};
};

export const getUniqueByKey = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()] || [];
};

export const stringToInt = (data, keys) => {
  keys?.map((e, i) => {
    if (data[e] == null || data[e] == "") {
      data[e] = 0;
    } else if (data[e].isString()) {
      data[e] = parseInt(data[e]);
    }
  });
  return data;
};

export const sortingList = (array, key) => {
  return array?.sort((a, b) => (a?.[key] > b?.[key] ? 1 : a?.[key] < b?.[key] ? -1 : 0));
};

export const encryptData = (data) => {
  let en = CryptoJS.AES.encrypt(data?.toString(), "AROHS")?.toString();
  let encoded = CryptoJS.enc.Base64.parse(en).toString(CryptoJS.enc.Hex);
  return encoded;
};

export const enc = (data) => {
  if (returningValue(data, "") != '') {
    const key = CryptoJS.enc.Utf8.parse(encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(ivKey);
    const encrypteddata = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    const encrypted = encrypteddata.toString()
    return encrypted
  } else {
    return null
  }
}

export const dec = (data) => {
  if (returningValue(data, "") != '') {
    const key = CryptoJS.enc.Utf8.parse(encryptKey);
    const iv = CryptoJS.enc.Utf8.parse(ivKey);
    const decryptedBytes = CryptoJS.AES.decrypt(data, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const decrypted = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decrypted || ""
  }
}

export const decryptData = (encoded) => {
  let temp = {};
  Object.entries(encoded)?.map(([key, value], i) => {
    if (i > 0) {
      let decoded1 = CryptoJS.enc.Hex.parse(value).toString(CryptoJS.enc.Base64);
      let decrypted1 = CryptoJS.AES.decrypt(decoded1, "AROHS")?.toString(CryptoJS.enc.Utf8);
      temp[key] = decrypted1;
    }
  });
  return temp;
};

export const removeDuplicates = (list, key) => {
  let result = [];
  list.filter((ele) => {
    if (result.filter((element) => element?.[key] == ele?.[key])?.length == 0) {
      result.push(ele);
    }
  });
  return result;
};

export const mobileDetect = () => {
  var agent = window.navigator.userAgent;
  var deviceWidth = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName("body")[0].clientWidth;

  // Chrome
  // var IsChromeApp = window.chrome && chrome.app && chrome.app.runtime;

  // iPhone
  var IsIPhone = agent.match(/iPhone/i) != null;

  // iPad up to IOS12
  var IsIPad = agent.match(/iPad/i) != null || (agent.match(/iPhone/i) != null && deviceWidth > 750); // iPadPro when run with no launch screen can have error in userAgent reporting as an iPhone rather than an iPad. iPadPro width portrait 768, iPhone6 plus 414x736 but would probably always report 414 on app startup

  if (IsIPad) IsIPhone = false;

  // iPad from IOS13
  var macApp = agent.match(/Macintosh/i) != null;
  if (macApp) {
    // need to distinguish between Macbook and iPad
    var canvas = document.createElement("canvas");
    if (canvas != null) {
      var context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
      if (context) {
        var info = context.getExtension("WEBGL_debug_renderer_info");
        if (info) {
          var renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
          if (renderer.indexOf("Apple") != -1) IsIPad = true;
        }
      }
    }
  }

  // IOS
  var IsIOSApp = IsIPad || IsIPhone;
  // Android
  var IsAndroid = agent.match(/Android/i) != null;
  var IsAndroidPhone = IsAndroid && deviceWidth <= 960;
  var IsAndroidTablet = IsAndroid && !IsAndroidPhone;
  var message = "";
  if (IsIPhone) {
    message = "Device is IsIPhone";
  } else if (IsIPad) {
    message = "Device is ipad";
  } else if (IsAndroidTablet || IsAndroidPhone || IsAndroid) {
    message = "Device is Android";
  } else {
    message = "Device is Mac ||  Windows Desktop";
  }
  return {
    message: message,
    isMobile: IsIOSApp || IsAndroid || IsAndroidTablet || IsAndroidPhone,
  };
};

export const filterFunction = (fil, array, filyterKeys) => {
  let f = fil?.toLowerCase()?.split(" ")?.join("");
  let res = array.filter((e) => {
    return filyterKeys.some((a) => {
      return e[a]?.toLowerCase()?.split(" ")?.join("")?.includes(f);
    });
  });
  return res;
};

export const navigationFn = (user, links) => {
  let roleId = user?.roleId;
  let link = sidenavbars?.find(v => (v?.linkName == links?.[0]?.linkName))
  let navigate = link?.child ? link?.child?.[0]?.route : link?.route
  // console.log(navigate, "navigate")
  let route = [1, 2, 3]?.some((e) => e == roleId) ? `${navigate ? navigate : "accessLinksNotAssigned"}`
    : roleId == 4 ? `${navigate ? navigate : "accessLinksNotAssigned"}`
      : roleId == 5 ? `${navigate ? navigate : "accessLinksNotAssigned"}` : ""
  return route;
};


const checkCondition = (v1, v2) => {
  return v1 == v2;
};



// export const exportExcel = (data, name) => {
//   const wb = XLSX.utils.book_new();
//   const ws = XLSX.utils.aoa_to_sheet(data);
//   const columnWidths = data.reduce((acc, row) => {
//     row.forEach((cell, i) => {
//       acc[i] = Math.max(acc[i] || 0, String(cell).length + 2);
//     });
//     return acc;
//   }, []);
//   ws["!cols"] = columnWidths.map((width) => ({ width }));
//   XLSX.utils.book_append_sheet(wb, ws, name);
//   XLSX.writeFile(wb, `${name}.xlsx`);
// }
export const exportExcel = (data, name, customHeader) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet(data);

  // Set column widths
  const columnWidths = data.reduce((acc, row) => {
    row.forEach((cell, i) => {
      acc[i] = Math.max(acc[i] || 0, String(cell).length + 2);
    });
    return acc;
  }, []);
  ws["!cols"] = columnWidths.map((width) => ({ width }));

  // Apply height for the header row (first row)
  if (!ws["!rows"]) ws["!rows"] = [];
  ws["!rows"][0] = { hpt: 30 }; // Set header row height in points

  // Apply styles to the header row
  const headerRow = data[0]; // Always take data[0] for header
  headerRow.forEach((_, colIndex) => {
    const cell_ref = XLSX.utils.encode_cell({ c: colIndex, r: 0 });
    if (ws[cell_ref]) {
      ws[cell_ref].s = {
        font: { bold: true },
        fill: { fgColor: { rgb: "99db8a" } }, // Background color for headers
        alignment: { horizontal: "left", vertical: "center" }, // Align to left
      };
    }
  });

  // If customHeader is provided, append data[1] after the header
  if (customHeader == 0) {
    if (!ws["!rows"]) ws["!rows"] = [];
    ws["!rows"][1] = { hpt: 25 };
    const dataToAppend = data[1] ? [data[1]] : []; // Only append data[1] if it exists
    const startRowIndex = 1; // Always start appending from row 1 after the header
    dataToAppend.forEach((row, rowIndex) => {
      const newRowIndex = startRowIndex + rowIndex;
      row.forEach((cell, colIndex) => {
        const cell_ref = XLSX.utils.encode_cell({ c: colIndex, r: newRowIndex });
        ws[cell_ref] = {
          v: cell, // Set the value
          s: {
            font: { bold: true },
            alignment: { horizontal: "left", vertical: "center" }, // Align to left
            fill: { fgColor: { rgb: "c5f6e2" } }, // Background color for data[1]
            border: {
              top: { style: "thick", color: { rgb: "000000" } }, // Border style
            },
          },
        };
      });
    });
  }

  // Append the worksheet and write to file
  XLSX.utils.book_append_sheet(wb, ws, name);
  XLSX.writeFile(wb, `${name}.xlsx`);
};


const returnTiming = (e) => {
  switch (e?.status) {
    case "Waiting List": return { appointmentTiming: e?.preferredDate, displayTimings: `${moment(e?.preferredDate).format("DD MMM")}  ${e?.preferredTime}` }
    case "Confirmed": return { appointmentTiming: e?.startTime, displayTimings: `${moment(e?.startTime).format("DD MMM")} [${moment(e?.startTime).format("hh:mm A")} - ${moment(e?.endTime).format("hh:mm A")}]` }
    case "Cancelled": return { appointmentTiming: (e?.inTime || e?.preferredDate || e?.startTime), displayTimings: returningValue(e?.inTime) != '' ? `${moment(e?.inTime).format("DD MMM")} [${moment(e?.inTime).format("hh:mm A")} - ${moment(e?.outTime).format("hh:mm A")}]` : (returningValue(e?.startTime) != '' ? `${moment(e?.startTime).format("DD MMM")} [${moment(e?.startTime).format("hh:mm A")} - ${moment(e?.endTime).format("hh:mm A")}]` : `${moment(e?.preferredDate).format("DD MMM")}  ${e?.preferredTime}`) }
    default: return { appointmentTiming: e?.inTime, displayTimings: `${moment(e?.inTime).format("DD MMM")} [${moment(e?.inTime).format("hh:mm A")} - ${moment(e?.outTime).format("hh:mm A")}]` }
  }

}
export const returnTimings = (e) => {
  return {
    ...returnTiming(e),
    paymentStatusShow: e?.totalAppointmentCost == (e?.paidAmount == null ? 0 : e?.paidAmount) ? "Paid" : e?.paidAmount == 0 ? "Pending" : "Partially paid",
    // appointmentTiming: e?.status == "Waiting List" ? e?.preferredDate
    //   : ["Confirmed"]?.some(v => v == e?.status) ? e?.startTime
    //     : ["Cancelled"]?.some(v => v == e?.status) ? (e?.inTime || e?.preferredDate || e?.startTime)
    //       : e?.inTime,
    timeDifference: returningValue(e?.inTime, "") != '' ? moment(e?.startTime).diff(moment(e?.inTime), 'minutes') : 0,
    // displayTimings: e?.status == "Waiting List" ? `${moment(e?.preferredDate).format("DD MMM")}  ${e?.preferredTime}`
    //   : ["Confirmed"]?.some(v => v == e?.status) ? `${moment(e?.startTime).format("DD MMM")} [${moment(e?.startTime).format("hh:mm A")} - ${moment(e?.endTime).format("hh:mm A")}]`
    //     : ["Cancelled"]?.some(v => v == e?.status) ? (returningValue(e?.startTime) != '' ? `${moment(e?.startTime).format("DD MMM")} [${moment(e?.startTime).format("hh:mm A")} - ${moment(e?.endTime).format("hh:mm A")}]` : `${moment(e?.preferredDate).format("DD MMM")}  ${e?.preferredTime}`)
    //       : `${moment(e?.inTime).format("DD MMM")} [${moment(e?.inTime).format("hh:mm A")} - ${moment(e?.outTime).format("hh:mm A")}]`,
  }
}

export const formatAppointments = (res) => {
  res = res?.res?.map((e) => ({ ...e, resources: res?.resources?.filter((v) => v?.appointmentId == e?.appointmentId) }));
  res = res?.map((e) => ({ ...e, ...returnTimings(e) }))
  res = res?.sort((a, b) => new Date(a?.['appointmentTiming']) - new Date(b?.['appointmentTiming']))
  return res
}

export const returnPaymetIcon = (q) => {
  switch (q.paymentType) {

    case "Pay Later": return (q?.paymentStatusShow == "Paid" && q?.totalAppointmentCost == q?.paidAmount) ? <img src={PayLaterSuccess} title="Pay Later" height={30} /> : <img src={PayLaterPending} title="Pay Later" height={30} />;
    case "Cash": return (q?.paymentStatusShow == "Paid" && q?.totalAppointmentCost == q?.paidAmount) ? <img src={CashSuccess} title="Cash" height={30} /> : <img src={CashPending} title="Cash" height={30} />;
    case "Paid For Bulk": return (q?.paymentStatusShow == "Paid" && q?.totalAppointmentCost == q?.paidAmount) ? <img src={BulkSuccess} title="Paid for bulk" height={30} /> : <img src={BulkPending} title="Paid for Bulk" height={30} />
    case "Online":
    case "Pay Now": return (q?.paymentStatusShow == "Paid" && q?.totalAppointmentCost == q?.paidAmount) ? <img src={OnlineSuccess} title="Online" height={30} /> : <img src={OnlinePending} title="Online" height={30} />;
  }
};


export const getStatusOnDate = (statusTrack, workDate) => {
  const workDateFormatted = parseInt(moment(workDate).format("yyyyMMDD"));
  for (const status of statusTrack) {
    const fromDateFormatted = parseInt(moment(status.fromDate).format("yyyyMMDD"));
    const toDateFormatted = status.toDate ? parseInt(moment(status.toDate).format("yyyyMMDD")) : null;
    if (['OnVacation']?.some(e => e == status.status)) {
      if (workDateFormatted >= fromDateFormatted && (toDateFormatted === null || workDateFormatted <= toDateFormatted)) return status.status;
    } else {
      if (workDateFormatted > fromDateFormatted && (toDateFormatted === null || workDateFormatted < toDateFormatted)) return status.status;
    }
  }
  return null;
};

export const getTodayRecords = (data) => {
  const today = parseInt(moment().format("YYYYMMDD"));
  return data?.filter(v => {
    const fromDate = parseInt(moment(v.fromDate).format("YYYYMMDD"));
    const toDate = v.toDate ? parseInt(moment(v.toDate).format("YYYYMMDD")) : null;
    return fromDate <= today && (!toDate || toDate >= today);
  })?.reverse();
};
